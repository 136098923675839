import { hasCookie, setCookie } from "cookies-next";
import { Trans } from "next-i18next";
import { useEffect, useState } from "react";

import { useLocale } from "@calcom/lib/hooks/useLocale";
import { Button } from "@calcom/ui";

declare global {
  interface Window {
    gtag: any;
  }
}

export const COOKIE_CONSENT_KEY = "cookie-consent";

const CookieNotice = ({ onConsentUpdate }: { onConsentUpdate?: (consent: boolean) => void }) => {
  const { t } = useLocale();
  const ONE_YEAR = 365 * 24 * 60 * 60;
  const ONE_WEEK = 7 * 24 * 60 * 60;

  const [consent, setConsent] = useState(true);

  useEffect(() => {
    setConsent(hasCookie(COOKIE_CONSENT_KEY));
  }, []);

  const acceptCookie = () => {
    setConsent(true);
    setCookie(COOKIE_CONSENT_KEY, "true", { maxAge: ONE_YEAR });

    window.gtag("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
    });

    if (onConsentUpdate) {
      onConsentUpdate(true);
    }
  };

  const declineCookie = () => {
    setConsent(true);
    setCookie(COOKIE_CONSENT_KEY, "false", { maxAge: ONE_WEEK });

    if (onConsentUpdate) {
      onConsentUpdate(false);
    }
  };

  if (consent === true) {
    return null;
  }

  return (
    <div
      className="fixed bottom-0 left-2/4 mx-auto flex w-full -translate-x-2/4 justify-center bg-gradient-to-t from-black py-4 px-2 pt-16"
      style={
        {
          "--tw-gradient-from": "rgba(0,0,0,0.2)",
        } as any
      }>
      <div className="border-subtle bg-default text-default max-w-lg rounded-md border p-6 text-justify text-sm shadow-md">
        <div className="text mb-5">
          <p className="text-emphasis mb-4 font-medium">{t("cookie_notice_title")}</p>
          <p>
            <Trans i18nKey="cookie_notice_text">
              X
              <a className="underline" href="https://petla.app/datenschutz" target="_blank">
                Y
              </a>
              Z
            </Trans>
          </p>
        </div>
        <div className="flex justify-end space-x-2">
          <Button color="minimal" className="decline btn" onClick={declineCookie}>
            {t("decline_cookies")}
          </Button>

          <Button color="primary" onClick={acceptCookie}>
            {t("accept_cookies")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookieNotice;
